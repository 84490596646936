import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Inspired by PrimeNg template directive: PrimeTemplate
 * https://github.com/primefaces/primeng/blob/2444986ee9e47dc4a502ef0b1eaf2e87d51ad3e7/src/app/components/api/shared.ts
 */
@Directive({
  selector: '[stiiltTemplate]',
  standalone: true,
})
export class StiiltTemplateDirective {
  @Input('stiiltTemplate') public name!: string;

  constructor(public template: TemplateRef<unknown>) {}

  public getName(): string {
    return this.name;
  }
}
