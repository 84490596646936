<div class="h-full overflow-y-scroll overflow-x-hidden relative flex-c">
  @if (processingBatch && !isLoading) {
    <div
      class="sticky top-0 flex-shrink-0 xl:order-first xl:flex xl:flex-col bg-white border-b border-gray-separator z-20">
      <div class="p-4">
        <div class="flex-r justify-between">
          <div>
            <div class="flex-r gap-3">
              <h2
                class="text-lg font-medium text-gray-900"
                [innerHTML]="'current_batch.batch_name' | transloco: { name: processingBatch.uuid }"></h2>

              @if (!processingBatch.processedAt) {
                <stiilt-color-badge-status [color]="ColorBadgeStatusColor.BLUE">
                  {{ 'current_batch.processing_short' | transloco }}
                </stiilt-color-badge-status>
              } @else {
                @if (processingBatch.completelyProcessed) {
                  @if (isBatchPaid) {
                    <stiilt-color-badge-status [color]="ColorBadgeStatusColor.GREEN">
                      {{ 'current_batch.paid' | transloco }}
                    </stiilt-color-badge-status>
                  } @else if (batchPayment?.billedOn) {
                    <stiilt-color-badge-status [color]="ColorBadgeStatusColor.YELLOW">
                      {{ 'current_batch.billed_on' | transloco: { data: batchPayment?.billedOn! | translocoDate:{ dateStyle: 'short' } } }}
                    </stiilt-color-badge-status>
                  } @else {
                    <stiilt-color-badge-status [color]="ColorBadgeStatusColor.ORANGE">
                      {{ 'current_batch.waiting_payment' | transloco }}
                    </stiilt-color-badge-status>
                  }
                } @else {
                  <stiilt-color-badge-status [color]="ColorBadgeStatusColor.RED">
                    {{ 'current_batch.error' | transloco }}
                  </stiilt-color-badge-status>
                }
              }
            </div>
            <p class="text-sm font-semibold text-gray-500">
              {{ 'parking_tickets.createdAt' | transloco: { date: processingBatch.createdAt! | translocoDate } }}
            </p>
            <ng-container *ngIf="processingBatch.processedAt">
              <p
                class="text-sm text-gray-500"
                [innerHTML]="
                  (processingBatch.completelyProcessed
                    ? 'current_batch.processed_at'
                    : processingBatch.processedAt
                      ? 'current_batch.not_completely_processed'
                      : 'current_batch.processing'
                  ) | transloco: { date: processingBatch.processedAt | translocoDate }
                "></p>
              <p
                class="text-sm text-gray-500"
                *ngIf="currentBatchState === ParkingTicketStates.PROCESSED_AND_PAID"
                [innerHTML]="'current_batch.total_fps' | transloco: { value: totalCount }"></p>
              <p
                class="text-sm text-gray-500"
                *ngIf="!processingBatch.completelyProcessed"
                [innerHTML]="'current_batch.not_processed' | transloco: { value: getHowManyNotProcessed }"></p>
            </ng-container>
            <p
              *ngIf="isAdmin && currentBatchState === ParkingTicketStates.PROCESSED_AND_NOT_PAID"
              class="text-sm font-semibold text-gray-500">
              {{ 'current_batch.awaiting_client_payment' | transloco }}
            </p>
          </div>
          <div class="flex-r items-center gap-5">
            <ng-container *ngIf="headerTemplate">
              <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            </ng-container>
            <div class="flex-r items-center gap-5">
              <p-button
                *ngIf="shouldDisplayPaymentButton"
                [label]="'current_batch.pay' | transloco"
                (onClick)="triggerPayment()"></p-button>

              <p-button
                *ngIf="shouldDisplayInvoiceButton"
                [label]="'current_batch.show_invoice' | transloco"
                (onClick)="triggerInvoiceCreationOrGetInvoice()"></p-button>

              <p-button
                *ngIf="isBatchPaid || isAdmin"
                [label]="'current_batch.download_csv' | transloco"
                (onClick)="openExportModal()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow">
      <p-table
        paginatorDropdownAppendTo="body"
        *ngIf="
          currentBatchState === ParkingTicketStates.PROCESSED_BUT_WITH_ERRORS ||
          currentBatchState === ParkingTicketStates.PROCESSED_AND_PAID ||
          currentBatchState === ParkingTicketStates.PROCESSED_AND_WILL_BE_PAID_BY_CARD_AND_LATER ||
          currentBatchState === ParkingTicketStates.PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE ||
          currentBatchState === ParkingTicketStates.PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE_AND_LATER ||
          isAdmin
        "
        [selectionMode]="processingBatch.processedAt ? 'single' : undefined"
        (onLazyLoad)="onLazyLoad($event)"
        [lazy]="true"
        [lazyLoadOnInit]="false"
        [first]="(page - 1) * size"
        [rows]="size"
        [totalRecords]="totalCount!"
        [paginator]="true"
        [rowsPerPageOptions]="[10, 20, 30, 50]"
        [showCurrentPageReport]="true"
        [columns]="tableColumns"
        [value]="entities">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ngClass]="{ 'text-center actions': col.field === 'actions' }">
              {{ col.name | transloco }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-columns="columns">
          <tr (click)="onRowSelect(row)">
            <td *ngFor="let col of columns">
              @switch (col.field) {
                @case (ParkingTicketTableColumns.amount) {
                  {{ (row.minoredAmount ? row.minoredAmount : row.amount) | currency: 'EUR' }}
                  <i
                    class="fa-solid fa-circle-info"
                    *ngIf="row.minoredAmount"
                    [pTooltip]="
                      'parking_ticket.amount_if_minor_amount' | transloco: { value: row.amount | currency: 'EUR' }
                    "></i>
                }
                @case (ParkingTicketTableColumns.paymentNotice) {
                  {{
                    row.paymentNotice
                      | fallbackTranslate
                      : 'parking_tickets.pt_number'
                        : (processingBatch.processedAt
                        ? 'parking_tickets.pt_number_not_recognized'
                        : 'current_batch.fps_processing')
                      | async
                  }}
                }
                @case (ParkingTicketTableColumns.offenceDate) {
                  {{ row.offenceDate | translocoDate }}
                }
                @case (ParkingTicketTableColumns.actions) {
                  <div class="flex flex-row justify-end items-center gap-2">
                    <i
                      class="fa-solid fa-hourglass-end"
                      *ngIf="row.paymentStatus === ParkingTicketPaymentStatus.PAYMENT_OVERDUE"
                      [pTooltip]="'parking_tickets.payment_overdue' | transloco"
                      tooltipPosition="left"></i>
                    <ng-container *ngIf="currentBatchState === ParkingTicketStates.PROCESSED_AND_PAID">
                      <i
                        class="fa-solid fa-money-bill-wave"
                        *ngIf="row.paymentStatus === ParkingTicketPaymentStatus.ALREADY_PAID"
                        [pTooltip]="'parking_tickets.already_paid' | transloco"
                        tooltipPosition="left"></i>
                      <i
                        *ngIf="row.paymentStatus !== ParkingTicketPaymentStatus.ALREADY_PAID"
                        class="fa-solid fa-file-invoice-dollar"
                        [pTooltip]="
                        (row.paidToStateDate
                          ? 'parking_tickets.paid_to_state_date'
                          : 'parking_tickets.not_yet_paid_to_state'
                        ) | transloco: { value: row.paidToStateDate | translocoDate }
                      "
                        tooltipPosition="left"
                        (click)="handlePaymentProofClick(row, $event)"></i>
                    </ng-container>
                    <stiilt-status-indicator
                      *ngIf="row.processedAt && !row.requiresInput"
                      [toolTipText]="
                        row.processedAt
                          ? ('parking_tickets.processed_at'
                            | transloco
                              : {
                                  value: row.processedAt | translocoDate: { dateStyle: 'medium', timeStyle: 'medium' }
                                })
                          : undefined
                      "
                      [type]="StatusType.SUCCESS"></stiilt-status-indicator>
                    <stiilt-color-badge-status
                      *ngIf="row.requiresInput && row.manualInputReason"
                      [color]="ColorBadgeStatusColor.RED">
                      {{ 'parking_tickets.' + row.manualInputReason | transloco }}
                    </stiilt-color-badge-status>
                    <p-button
                      class="pl-3"
                      *ngIf="row.requiresInput"
                      icon="fa-solid fa-pen"
                      (click)="handleManualInput($event, row)"></p-button>
                  </div>
                }
                @default {
                  {{ row[col.field] }}
                }
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <span>{{ 'app.total' | transloco }} : {{ totalCount ?? 0 }} {{ 'current_batch.results' | transloco }}</span>
        </ng-template>
        <ng-template let-columns pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="columns.length" class="text-center">
              <div class="flex flex-col">
                <i class="fa-solid fa-inbox"></i>
                {{ 'app.no_record_found' | transloco }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="currentBatchState === ParkingTicketStates.NOT_PROCESSED && !isAdmin" class="flex-col-center h-full">
        <stiilt-empty-state [title]="'current_batch.processing' | transloco"></stiilt-empty-state>
      </div>
      <ng-container *ngIf="currentBatchState === ParkingTicketStates.PROCESSED_AND_NOT_PAID">
        <div
          *ngIf="!waitingAfterPayment"
          class="h-full w-full">
          @if (!isAdmin) {
            <stiilt-stripe-payment
              *ngIf="clientSecret"
              [clientSecret]="clientSecret"
              (paymentSuccess)="handlePaymentSuccess()"
              class="w-full h-full"></stiilt-stripe-payment>
            <div *ngIf="!clientSecret && isClientSecretError" class="h-full flex-col-center gap-3">
              <p>{{ 'current_batch.errors.payment_error' | transloco }}</p>
              <p-button [label]="'current_batch.retry_payment' | transloco" (onClick)="handleRetryPayment()"></p-button>
            </div>
          }
        </div>
        <div
          *ngIf="waitingAfterPayment"
          class="flex-col-center h-full">
          <stiilt-spinner [indeterminate]="true" />
        </div>
      </ng-container>
    </div>
  } @else {
    <div class="flex-col-center h-full">
      <stiilt-spinner></stiilt-spinner>
    </div>
  }
</div>
<stiilt-parking-tickets-detail-sidebar
  [clickedParkingTicket]="clickedParkingTicket"
  [visible]="sidebarVisible"
  (sideBareClosed)="sidebarVisible = false"
  [currentBatchState]="currentBatchState"></stiilt-parking-tickets-detail-sidebar>
