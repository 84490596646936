import { Component, Input } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

@Component({
  selector: 'stiilt-step',
  standalone: true,
  template: ` <ng-template>
    <ng-content></ng-content>
  </ng-template>`,
  providers: [{ provide: CdkStep, useExisting: StiiltStepComponent }],
})
export class StiiltStepComponent extends CdkStep {
  @Input() public identifier?: string;
}
