<div class="dialog-container flex flex-col" [class]="maxHeightClassName">
  <ng-container *ngIf="headerTemplate; else header">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </ng-container>
  <div class="content h-full">
    <ng-content></ng-content>
  </div>
  <div *ngIf="withFooter" class="footer flex-shrink-0">
    <ng-container *ngIf="footerTemplate; else footer">
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </ng-container>
    <ng-template #footer>
      <div>
        <button
          (click)="onCancel($event)"
          [label]="_cancelButtonText"
          [ngClass]="{'p-button-text': !rejectDestructive, 'p-button-danger': rejectDestructive}"
          pButton
          data-cy="cancelButton"
          type="button"></button>
        <button
          (click)="actionClickedAndConfirmed($event)"
          [disabled]="disableActionButton"
          [label]="_actionButtonText"
          [loading]="isActionButtonLoading"
          class="ml-1"
          data-cy="actionButton"
          pButton></button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #header>
  <div class="header flex-shrink-0">
    <h2 class="text-2xl">{{ title }}</h2>
    <div class="button-wrapper">
      <button
        tabindex="-1"
        (click)="onClose($event)"
        class="p-button p-button-rounded p-button-danger p-button-text grow justify-center"
        type="button">
        <i [pTooltip]="'app.close' | transloco" [fitContent]="true" class="fa-solid fa-xmark" tooltipPosition="left"></i>
      </button>
    </div>
  </div>
</ng-template>
