import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ProcessingBatchUtilsService } from '@shared/services/processing-batch-http.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  VehicleAssignmentColumnMapping,
  VehicleAssignmentColumnMappingRaw,
} from '@shared/models/vehicle-assignment/vehicle-assignment-column-mapping.model';
import { VehicleAssignmentHttpService } from '@shared/components/steps/update-vehicle-assignments/services/vehicle-assignment-http.service';
import { CsvExport } from '@shared/models/processing-batch/csv-export.model';
import { SelectItem } from 'primeng/api/selectitem';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { Message } from 'primeng/api';

@Component({
  selector: 'stiilt-export-csv-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './export-csv-modal.component.html',
  styles: ``,
})
export class ExportCsvModalComponent {
  public availableFieldsToExport!: Partial<VehicleAssignmentColumnMappingRaw>;
  public ptSelectedFields!: string[];
  public additionalSelectedFields!: string[];
  public selectedDate?: string;
  public additionalAvailableFieldsToExportEntries: [string, string][] = [];
  public defaultFields: string[] = [];
  public staticFleetColumnMapping!: VehicleAssignmentColumnMapping;
  protected readonly isMonthlyExport;
  protected readonly dates: SelectItem[] | undefined;
  protected warningMessage!: Message[];

  constructor(
    private readonly processingBatchUtilsService: ProcessingBatchUtilsService,
    private readonly staticFleetHttpService: VehicleAssignmentHttpService,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly dynamicDialogConfig: DynamicDialogConfig<{ isMonthlyExport?: boolean; dates?: string[] }>,
    private readonly datePipe: DatePipe,
    private readonly translocoService: TranslocoService,
  ) {
    this.isMonthlyExport = this.dynamicDialogConfig.data?.isMonthlyExport;

    if (this.isMonthlyExport && this.dynamicDialogConfig.data?.dates) {
      this.dates = this.dynamicDialogConfig.data.dates.map((date) => {
        return {
          label: datePipe.transform(date, 'MMMM yyyy', undefined, this.translocoService.getActiveLang())!,
          value: date,
        };
      });
      this.selectedDate = this.dates[0].value;
    }

    this.staticFleetHttpService
      .getStaticFleetCsvHeaders()
      .subscribe((staticFleetColumnMapping: VehicleAssignmentColumnMapping) => {
        this.staticFleetColumnMapping = staticFleetColumnMapping;

        const defaultFields = staticFleetColumnMapping
          ? processingBatchUtilsService.defaultFieldsWithMappings
          : processingBatchUtilsService.defaultFieldsWithoutMappings;

        this.defaultFields = [...defaultFields];
        this.ptSelectedFields = [...defaultFields];

        if (staticFleetColumnMapping) {
          const {
            id,
            createdAt,
            updatedAt,
            deletedAt,
            organisationId,
            organisation,
            startDateFormat,
            endDateFormat,
            ...rest
          } = staticFleetColumnMapping;
          this.availableFieldsToExport = Object.fromEntries(Object.entries(rest).filter(([_, value]) => value != null));
          this.additionalAvailableFieldsToExportEntries = Object.entries(this.availableFieldsToExport);
          this.additionalSelectedFields = [
            ...(Object.keys(this.availableFieldsToExport) as (keyof VehicleAssignmentColumnMapping)[]),
          ];
        }
      });

    this.translocoService.selectTranslation().subscribe(() => {
      this.warningMessage = [
        { severity: 'warn', detail: this.translocoService.translate('current_batch.export_warning_payment') },
      ];
    });
  }

  public handleActionButton(): void {
    this.dynamicDialogRef.close({
      ptSelectedFields: this.ptSelectedFields,
      additionalSelectedFields: this.additionalSelectedFields,
      headerMapping: this.availableFieldsToExport,
      selectedDate: this.selectedDate,
    } as CsvExport);
  }
}
