import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateUtils } from '../../utils/date.utils';
import { CalendarModule } from 'primeng/calendar';

/**
 *
 * Wrapper for p-calendar, to convert string date provided by backend into JS Date object.
 * In order to use range mode, use only onRangeSelect, and not onSelect or formControl.
 *
 */
@UntilDestroy()
@Component({
  selector: 'stiilt-calendar',
  templateUrl: './stiilt-calendar.component.html',
  styleUrls: ['./stiilt-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StiiltCalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StiiltCalendarComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CalendarModule, ReactiveFormsModule, FormsModule],
})
export class StiiltCalendarComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() public inputStyleClass!: string;
  @Input() public dateFormat = 'dd/mm/yy';
  @Input() public appendTo = 'body';
  @Input() public readonlyInput!: boolean;
  @Input() public required!: boolean;
  @Input() public panelStyleClass!: string;
  @Input() public minDate!: Date;
  @Input() public maxDate!: Date;
  @Input() public showTime = false;
  @Input() public selectionMode: 'single' | 'range' | 'multiple' = 'single';

  @Input() public label!: string;

  @Output() public dateSelected: EventEmitter<string | Date> = new EventEmitter();
  @Output() public rangeSelected: EventEmitter<Date[] | string[]> = new EventEmitter();
  public isDisabled = false;
  private _onChange!: (value: string | Date) => void;
  private _onTouched!: () => void;
  private _onValidationChange!: () => void;
  public value!: Date | null;
  public id: string;

  constructor() {
    this.id = uuid();
  }

  ngOnInit(): void {}

  public registerOnChange(fn: (value: string | Date) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(date: string | Date): void {
    this.value = date ? new Date(date) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onValidationChange = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.hasValidator(Validators.required)) {
      return this.value ? null : { required: true };
    }
    return null;
  }

  public onDateChange(event: Date): void {
    if (this.showTime) {
      this.dateSelected.emit(event);
      this._onChange(event);
    } else {
      this.dateSelected.emit(DateUtils.toApiDate(event)!);
      this._onChange(DateUtils.toApiDate(event)!);
    }
    this._onTouched();
    this._onValidationChange();
  }
}
