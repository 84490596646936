import { Component, forwardRef, Input, QueryList } from '@angular/core';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { StiiltStepComponent } from './stiilt-step/stiilt-step.component';

@Component({
  selector: 'stiilt-invisible-stepper',
  standalone: true,
  imports: [forwardRef(() => InvisibleStepperComponent), CdkStepperModule, NgClass, NgTemplateOutlet, NgForOf, NgIf],
  providers: [{ provide: CdkStepper, useExisting: InvisibleStepperComponent }],
  templateUrl: './invisible-stepper.component.html',
  styles: ``,
})
export class InvisibleStepperComponent extends CdkStepper {
  @Input() public showHeader = true;
  public override readonly steps: QueryList<StiiltStepComponent> = new QueryList<StiiltStepComponent>();
}
