import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'stiilt-empty-state',
  standalone: true,
  imports: [NgIf],
  templateUrl: './empty-state.component.html',
  styles: ``,
})
export class EmptyStateComponent {
  @Input({ required: true }) title!: string;
  @Input() description!: string;
}
