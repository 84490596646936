import { Component, Input } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { EnvService } from '@core/services/env.service';

@Component({
  selector: 'stiilt-app-icon',
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgClass],
  templateUrl: './app-icon.component.html',
  styleUrl: './app-icon.component.scss',
})
export class AppIconComponent {
  @Input() iconOnly: boolean = false;
  public isDemoMode: boolean = false;

  constructor(public envService: EnvService) {
    this.isDemoMode = localStorage.getItem('demo-mode') === 'true';
  }
}
