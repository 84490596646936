import { Injectable } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Constants } from '../constants/constants';
import { PageChangeEvent } from '../models/paginator.model';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  public getPageChangeEvent(event: TableLazyLoadEvent): PageChangeEvent {
    return {
      index: event.first === 0 ? event.first : Math.floor(event.first! / event.rows!),
      size: event.rows ? event.rows : Constants.DEFAULT_PAGE_SIZE,
    };
  }
}
