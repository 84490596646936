import { Component, Input } from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'stiilt-toggle-menu',
  standalone: true,
  imports: [MenuModule, ButtonModule],
  templateUrl: './toggle-menu.component.html',
  styles: ``,
})
export class ToggleMenuComponent {
  @Input() public icon = 'fa-solid fa-bars';
  @Input() public size: 'small' | 'large' = 'small';
  @Input({ required: true }) public items: any[] = [];
}
