import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@core/services/env.service';
import { catchError, EMPTY, map, Observable, ReplaySubject } from 'rxjs';
import { User } from '@pages/account/models/user.model';
import { ɵFormGroupValue, ɵTypedOrUntyped } from '@angular/forms';
import { ControlsOf } from '@shared/models/controls-of';
import { PasswordChange } from '@pages/account/models/password-change.model';
import { ToastService } from '@shared/services/toast.service';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class ProfileHttpService {
  private userProfile = new ReplaySubject<User>(1);
  public userProfile$ = this.userProfile.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvService,
    private readonly toastsService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  public getProfile(): void {
    this.http
      .get<User>(`${this.env.baseUrl}/users`)
      .pipe(
        map((result: User) => {
          this.userProfile.next(result);
        }),
      )
      .subscribe();
  }

  updatePassword(userId:number,password: string):Observable<void> {
    return this.http.patch<void>(`${this.env.baseUrl}/users/${userId}/password`, { password }).pipe(
       map(() => {
         this.toastsService.success(this.translocoService.translate('account.profile.password_change_success'));
       }),
       catchError((error) => {
         console.error('Error updating password', error);
         this.toastsService.error(this.translocoService.translate('account.profile.password_change_error'));
       return EMPTY
       })
     )
  }
}
