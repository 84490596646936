<div class="p-inputgroup phone-group w-full" #input>
  <button type="button" class="p-button-outlined" pButton [icon]="selectedIconClass"
    (click)="onOpenOverlay($event)"></button>
  <div class="flex flex-row w-full gap-4">
    <input pInputText class="w-[75px] excluded" [value]="currentPhoneNumber?.dialCode" readonly />
    <span class="p-float-label">
      <input [ngClass]="{'ng-dirty ng-invalid': isTouched && isInvalid}" (focusout)="handleOnTouche()"
        (input)="handleInputChange($event)" id="phone" pInputText type="tel" />
      <label for="phone">{{ 'account.personal_information.phoneNumber' | transloco }}</label>
    </span>
  </div>
</div>

<p-overlayPanel #popUp appendTo="body">
  <ng-template pTemplate="content">
    <div class="max-h-[200px] overflow-y-scroll bg-red" [ngStyle]="{ width: width, 'min-width': width }">
      <ul>
        <li *ngFor="let phoneNumber of phonesService.phoneCountryCodes$ | async; index as i">
          <div class="flex gap-5 hover:bg-gray-50 cursor-pointer"
            (click)="handlePhoneCountryChange(phoneNumber, $event)">
            <i class="fi" [ngClass]="getCountryClass(phoneNumber.isoCode)"></i>
            <div class="min-w-[4rem]">
              <span class="text-gray-400">{{ phoneNumber.dialCode }}</span>
            </div>
            <span>{{ phoneNumber.name }}</span>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</p-overlayPanel>
