import { AfterViewInit, Component, ElementRef, forwardRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import {
  AsYouType,
  getExampleNumber,
  isValidPhoneNumber,
  ParseError,
  parsePhoneNumberWithError,
  PhoneNumber as GPhoneNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import { CountryCode } from 'libphonenumber-js/types';
import { PhoneNumber, PhoneNumbersService } from '@shared/services/phone-numbers.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoPipe } from '@jsverse/transloco';
import { PrimengModule } from '@shared/primeng.module';

@Component({
  selector: 'stiilt-phone-input',
  standalone: true,
  imports: [OverlayPanelModule, CommonModule, TranslocoModule, PrimengModule, TranslocoPipe],
  templateUrl: './stiilt-phone-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StiiltPhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StiiltPhoneInputComponent),
      multi: true,
    },
    PhoneNumbersService,
  ],
})
export class StiiltPhoneInputComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
  @ViewChild('input') public input!: ElementRef;
  @ViewChild('overlay') public overlay!: ElementRef;
  @ViewChild('popUp') public popUp!: OverlayPanel;

  public value: string = '';
  public width!: string;
  public currentPhoneNumber!: PhoneNumber;
  public currentExamplePhoneNumber!: GPhoneNumber;
  public selectedIconClass!: string;

  private _onChange: (value: string) => void = () => {};
  public _onTouched: () => void = () => {};
  private _onValidationChange: () => void = () => {};

  public disabled = false;

  public isInvalid = false;
  public isTouched = false;

  constructor(public phonesService: PhoneNumbersService) {}
  ngOnInit(): void {
    this.phonesService.init();
    this.phonesService.currentSelectedCountryPhoneCode$.subscribe((phoneNumber) => {
      this.currentPhoneNumber = phoneNumber;
      this.setExamplePhoneNumber();
      this.updateCurrentLangFlag();
    });
  }

  ngAfterViewInit(): void {
    this.width = `${this.input.nativeElement.offsetWidth - 40}px`;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.hasValidator(Validators.required)) {
      if (!this.value) return { required: true };

      if (this.isNumberInvalid()) {
        return { format: this.getNumberError() };
      }
      return null;
    }

    if (this.isNumberInvalid()) {
      return { format: this.getNumberError() };
    }
    return null;
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }
  }

  // Util functions
  getCountryClass(isoCode: CountryCode) {
    return `fi-${isoCode.toLowerCase()}`;
  }

  onOpenOverlay(event: Event) {
    this.popUp.el.nativeElement.style.width = `${this.width}px`;
    this.popUp.toggle(event);
  }

  updateCurrentLangFlag(): void {
    this.selectedIconClass = `fi fi-${(this.currentPhoneNumber?.isoCode as string)?.toLowerCase()}`;
  }
  public isNumberInvalid(): boolean {
    return !isValidPhoneNumber(this.currentPhoneNumber.dialCode + this.value, this.currentPhoneNumber.isoCode);
  }

  private getNumberError() {
    try {
      return parsePhoneNumberWithError(this.value);
    } catch (error) {
      if (error instanceof ParseError) {
        return error.message;
      }
      throw error;
    }
  }

  handlePhoneCountryChange(phoneNumber: PhoneNumber, event: Event) {
    this.popUp.toggle(event);
    this.phonesService.setSelectedPhoneNumber(phoneNumber);
    this.triggerValidityCheck();
  }

  handleInputChange(event: Event) {
    this.value = (event.target as HTMLInputElement).value;
    const formattedValue = new AsYouType('FR').input(this.currentPhoneNumber.dialCode + this.value);
    this.triggerValidityCheck();
    this._onChange(formattedValue);
  }

  private triggerValidityCheck(): void {
    this.isInvalid = this.isNumberInvalid();
    this._onValidationChange();
  }

  private setExamplePhoneNumber() {
    this.currentExamplePhoneNumber != getExampleNumber(this.currentPhoneNumber?.isoCode, examples);
  }

  handleOnTouche() {
    this._onTouched();
    this.isTouched = true;
  }
}
