import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentPlanTypeEnum } from '@shared/types/payment-plan.type';
import { PaymentHttpServiceService } from '@shared/services/payment-http.service';
import { NgClass, NgForOf } from '@angular/common';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonModule } from 'primeng/button';
import { PaymentPlan } from '@shared/models/payment-plan/payment-plan.model';
import { AvailableLangs } from '../../../transloco-root.module';

@UntilDestroy()
@Component({
  selector: 'stiilt-plan-chooser',
  standalone: true,
  templateUrl: './plan-chooser.component.html',
  styles: ``,
  imports: [NgForOf, TranslocoPipe, FormsModule, SelectButtonModule, ButtonModule, NgClass],
})
export class PlanChooserComponent implements OnInit {
  @Input() currentPlan!: PaymentPlan;
  @Input() customPlanId!: number;

  @Output() public selectedPlan: EventEmitter<{
    paymentPlan: PaymentPlan | undefined;
    periodicity: Periodicity | undefined;
  }> = new EventEmitter<{
    paymentPlan: PaymentPlan | undefined;
    periodicity: Periodicity | undefined;
  }>();

  public activePlan!: PaymentPlan;
  public activePeriodicity!: Periodicity;

  public selectedPaymentPlan: PaymentPlan | undefined;
  public selectedPeriodicity: Periodicity | undefined;

  public existingPlans: PaymentPlan[] = [];

  public periodicity: Periodicity = Periodicity.MONTHLY;
  public stateOptions!: Array<{ label: string; value: Periodicity }>;

  public currentLang!: AvailableLangs;

  constructor(
    private readonly planService: PaymentHttpServiceService,
    private readonly translateService: TranslocoService,
  ) {}

  public ngOnInit(): void {
    this.activePlan = this.currentPlan;
    this.activePeriodicity = Periodicity.MONTHLY;

    this.selectedPaymentPlan = this.currentPlan;
    this.selectedPeriodicity = Periodicity.MONTHLY;

    // If we have a custom payment plan we just get that instead and show it.
    if (this.customPlanId) {
      this.planService.getPlanById(this.customPlanId);
    } else {
      this.planService.getPlans();
    }

    this.planService.existingPlans$.subscribe((plans) => {
      this.existingPlans = plans;
    });

    merge(this.translateService.events$, this.translateService.langChanges$)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.currentLang = this.translateService.getActiveLang() as AvailableLangs;
        this.stateOptions = [
          {
            label: this.translateService.translate('account_plan.personal_information.billing_periods.monthly'),
            value: Periodicity.MONTHLY,
          },
          {
            label: this.translateService.translate('account_plan.personal_information.billing_periods.annually'),
            value: Periodicity.ANNUAL,
          },
        ];
      });
  }

  public isCurrentPlan(planType: PaymentPlanTypeEnum): boolean {
    return this.activePlan.type === planType;
  }

  public getMonthlyPrice(plan: PaymentPlan): number {
    if (this.periodicity === Periodicity.ANNUAL) {
      return parseFloat((plan.annualFeeInEuros / 12).toFixed(2));
    } else {
      return plan.monthlyFeeInEuros;
    }
  }

  public handlePlanSelection(plan: PaymentPlan) {
    if (this.activePlan.type === plan.type) {
      this.selectedPeriodicity = this.periodicity;
      this.selectedPaymentPlan = this.activePlan;
      this.selectedPlan.emit({ paymentPlan: undefined, periodicity: undefined });
    } else {
      this.selectedPaymentPlan = plan;
      this.selectedPeriodicity = this.periodicity;
      this.selectedPlan.emit({ paymentPlan: plan, periodicity: this.periodicity });
    }
  }
}

export enum Periodicity {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}
