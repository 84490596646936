import LatLngBounds = google.maps.LatLngBounds;
import LatLng = google.maps.LatLng;
import ComponentRestrictions = google.maps.places.ComponentRestrictions;

export class Options {
  public bounds!: LatLngBounds;
  public componentRestrictions!: ComponentRestrictions;
  public types!: string[];
  public fields!: string[];
  public strictBounds!: boolean;
  public origin!: LatLng;
  public constructor(opt?: Partial<Options>) {
    if (!opt) return;

    Object.assign(this, opt);
  }
}
