import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { remove } from 'lodash';

@Directive({
  selector: '[stiiltSameWidthColumn]',
  standalone: true,
})
export class SameWidthColumnDirective implements AfterViewInit {
  private widthOfExcludedColumns = 3;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    // Here we select all tr and not all th because same table have a second line in header for the filters
    // So by selecting the first row, we ensure that only the table columns name are selected.
    const allHeaders = [...this.el.nativeElement.getElementsByTagName('tr')[0].children];

    const headers = remove(allHeaders, (header) => {
      if (header.className) {
        if (!header.className.includes('actions')) {
          return header;
        } else {
          return null;
        }
      } else {
        return header;
      }
    });

    const headersToExclude = [...allHeaders];

    const nbHeaders = headers.length;
    const nbHeaderToExclude = headersToExclude.length;

    for (const header of headers) {
      if (nbHeaderToExclude) {
        header.style = `width: ${(100 - nbHeaderToExclude * this.widthOfExcludedColumns) / nbHeaders}%`;
      } else {
        header.style = `width: ${100 / nbHeaders}%`;
      }
    }

    for (const header of headersToExclude) {
      header.style = `width: ${this.widthOfExcludedColumns}%`;
    }
  }
}
