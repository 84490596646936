<span class='mt-2 p-float-label'>
  <p-calendar
    (onSelect)='onDateChange($event)'
    [(ngModel)]="value"
    [appendTo]='appendTo' [dateFormat]='dateFormat' [id]='id'
    [inputId]='id' [inputStyleClass]='inputStyleClass' [panelStyleClass]='panelStyleClass'
    [readonlyInput]='readonlyInput'
    [required]='required'
    [minDate]='minDate'
    [maxDate]='maxDate'
    [showTime]='showTime'
    [selectionMode]='selectionMode'>
  </p-calendar>
  <label [for]='id'>{{ label }}</label>
</span>
