import { TraceableModel } from '../traceable.model';
import { ParkingTicketAddressModel } from './parking-ticket-address.model';
import { ProcessingBatch } from './processing-batch.model';
import { ManualInputReason } from '../../types/manual-input-reason.type';
import { ParkingTicketPaymentStatus } from '../../types/parking-ticket-payment.status';
import { VehicleAssignment } from '@shared/models/vehicle-assignment/vehicle-assignment.model';

export interface ParkingTicket extends TraceableModel {
  path: string;
  amount: number;
  minoredAmount: number;
  offenceDate: Date | string;
  parkingTicketSentAt: string;
  collectivityName: string;
  address: ParkingTicketAddressModel;
  paymentNoticeFull: string;
  paymentNotice: string;
  paymentKey: string;
  requiresInput: boolean;
  manualInputReason: ManualInputReason;
  rawDetectionData: { [key: string]: any };
  duplicatedOfId: number;
  duplicateOfParkingTicket: ParkingTicket;
  duplicateParkingTickets: ParkingTicket[];
  licencePlate: string;
  paymentStatus: ParkingTicketPaymentStatus;
  paidToStateDate: Date;
  processedAt: Date;
  processingBatchId: number;
  processingBatch: ProcessingBatch;
  deadlineToStatePayment: string;
  rentalReference: string;
  vehicleBrand: string;
  paymentProofPath: string;
  vehicleAssignmentId: number;
  vehicleAssignment: VehicleAssignment;
}

export enum ParkingTicketTableColumns {
  id = 'id',
  licencePlate = 'licencePlate',
  paymentNotice = 'paymentNotice',
  amount = 'amount',
  offenceDate = 'offenceDate',
  vehicleBrand = 'vehicleBrand',
  actions = 'actions',
}
