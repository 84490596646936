import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CountryCode } from 'libphonenumber-js/types';

export interface PhoneNumber {
  name: string;
  dialCode: string;
  isoCode: CountryCode;
}

@Injectable({
  providedIn: 'root',
})
export class PhoneNumbersService {
  private phonesCode = new BehaviorSubject<PhoneNumber[]>([]);
  public phoneCountryCodes$ = this.phonesCode.asObservable();

  private currentSelectedCountryPhoneCode = new BehaviorSubject<PhoneNumber>({ name: '', dialCode: '', isoCode: 'FR' });
  public currentSelectedCountryPhoneCode$ = this.currentSelectedCountryPhoneCode.asObservable();

  constructor(private http: HttpClient) {}

  init(): void {
    this.http
      .get<PhoneNumber[]>('assets/phone/phones.json')
      .pipe(
        map((data) => {
          this.phonesCode.next(data);
          this.setDefaultCountryPhoneCode(data);
          return data;
        }),
      )
      .subscribe();
  }

  private setDefaultCountryPhoneCode(countryCodes: PhoneNumber[]): void {
    const fallbackLang = 'FR';
    this.currentSelectedCountryPhoneCode.next(
      countryCodes.find((phoneNumber) => (phoneNumber.isoCode as string) === fallbackLang)!,
    );
  }

  public setSelectedPhoneNumber(phoneNumbers: PhoneNumber): void {
    this.currentSelectedCountryPhoneCode.next(phoneNumbers);
  }
}
