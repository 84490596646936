import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslocoCurrencyPipe, TranslocoDatePipe } from '@jsverse/transloco-locale';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ParkingTicket } from '@shared/models/parking-ticket/parking-ticket.model';
import { FileService } from '@shared/services/file.service';
import { ProcessingBatchUtilsService } from '@shared/services/processing-batch-http.service';
import { StiiltDialogService } from '@shared/services/stiilt-dialog.service';
import { BatchStates } from '../../batch-detail.component';
import { PrimengModule } from '@shared/primeng.module';
import { NotSpecifiedPipe } from '@shared/pipes/not-specified.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { EmbeddedPdfViewerComponent } from '@shared/components/embedded-pdf-viewer/embedded-pdf-viewer.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'stiilt-parking-tickets-detail-sidebar',
  standalone: true,
  imports: [
    TranslocoCurrencyPipe,
    PrimengModule,
    TranslocoDatePipe,
    NotSpecifiedPipe,
    TranslocoPipe,
    EmbeddedPdfViewerComponent,
    NgIf,
    NgForOf,
    AsyncPipe,
  ],
  templateUrl: './parking-tickets-detail-side-bar.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StiiltDialogService],
})
export class ParkingTicketsDetailSideBarComponent implements OnChanges {
  @Input({ required: true }) clickedParkingTicket!: ParkingTicket | undefined;
  @Input({ required: true }) currentBatchState!: BatchStates;
  @Input({ required: true }) visible: boolean = false;
  @Output() sideBareClosed: EventEmitter<void> = new EventEmitter<void>();
  public showSidebar: boolean = false;
  public fieldsToDisplay: string[] = [
    'amount',
    'offenceDate',
    'collectivityName',
    'address',
    'rentalReference',
    'driverEmail',
    'driverReference',
    'parkingTicketSentAt',
  ];

  public filePathToDisplay: string | undefined;
  public paymentProof: string | undefined;

  constructor(
    private readonly fileService: FileService,
    public readonly processingBatchUtilsService: ProcessingBatchUtilsService,
  ) {}

  ngOnChanges(): void {
    this.showSidebar = this.visible;
  }

  public getValue(key: string) {
    return this.clickedParkingTicket?.[key as keyof ParkingTicket] ?? 'NC';
  }

  handleOnHide() {
    this.showSidebar = false;
    this.filePathToDisplay = undefined;
    this.paymentProof = undefined;
    this.sideBareClosed.emit();
  }

  handleOnShow() {
    this.filePathToDisplay = this.fileService.getBucketPath(this.clickedParkingTicket?.path!);
    if (this.currentBatchState === BatchStates.PROCESSED_AND_PAID && this.clickedParkingTicket?.paymentProofPath) {
      this.paymentProof = this.fileService.getBucketPath(this.clickedParkingTicket?.paymentProofPath);
    }
  }
}
