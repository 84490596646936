import { Component, Input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

export enum StatusType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  RUNNING = 'running',
}

@Component({
  selector: 'stiilt-status-indicator',
  standalone: true,
  imports: [TooltipModule],
  templateUrl: './status-indicator.component.html',
  styles: ``,
})
export class StatusIndicatorComponent {
  @Input() public type: StatusType = StatusType.SUCCESS;
  @Input() public toolTipText: string | undefined;

  private readonly statusClassMap = {
    [StatusType.SUCCESS]: 'text-green-400 bg-green-400/10',
    [StatusType.WARNING]: 'text-orange-400 bg-orange-400/10',
    [StatusType.DANGER]: 'text-red-400 bg-red-400/10',
    [StatusType.RUNNING]: 'text-sky-400 bg-sky-400/10',
  };

  public getStatusClass(): string {
    return this.statusClassMap[this.type];
  }
}
