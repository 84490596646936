import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StiiltTemplateDirective } from '../../directives/stiilt-template.directive';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { merge } from 'rxjs';
import { StatusEventsService } from '../../services/status-events.service';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'stiilt-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [TranslocoPipe, TooltipModule, ButtonModule, NgClass, NgIf, NgTemplateOutlet],
})
export class DialogComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(StiiltTemplateDirective) private templates!: QueryList<StiiltTemplateDirective>;

  @Input() public title = '';
  @Input() public enableConfirm = true;
  @Input() public confirmPopupMessage!: string;
  @Input() public actionButtonText!: string;
  @Input() public cancelButtonText!: string;
  @Input() public disableActionButton = false;
  @Input() public useCustomCancelButtonAction = false;
  @Input() public withFooter = true;
  @Input() public rejectDestructive = false;
  @Input() public isActionButtonLoading = false;
  @Input() private preventActionButtonLoading = false;
  @Input() public maxHeightClassName: string = 'max-h-[80vh]';
  @Output() public actionButton = new EventEmitter<MouseEvent>();
  @Output() public cancelButton = new EventEmitter<MouseEvent>();

  public headerTemplate!: TemplateRef<unknown>;
  public footerTemplate!: TemplateRef<unknown>;

  public _actionButtonText!: string;
  public _cancelButtonText!: string;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private translateService: TranslocoService,
    private statusEventService: StatusEventsService,
  ) {
    // FIXME: See why NG0100 error isn't show if the code below is commented
    this.statusEventService
      .getHttpStatus()
      .pipe(untilDestroyed(this))
      .subscribe((isOnAir: boolean) => {
        this.isActionButtonLoading = isOnAir;
      });

    merge(this.translateService.events$, this.translateService.langChanges$)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initTranslations();
      });
  }

  private initTranslations(): void {
    this._actionButtonText = this.actionButtonText ?? this.translateService.translate('app.ok');
    this._cancelButtonText = this.cancelButtonText ?? this.translateService.translate('app.cancel');
  }

  public ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getName()) {
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }

  public ngOnDestroy(): void {
    this.statusEventService.setHttpStatus(false);
  }

  public onClose(event: MouseEvent): void {
    this.dynamicDialogRef.close();
  }

  public onCancel(event: MouseEvent): void {
    if (this.useCustomCancelButtonAction) {
      this.cancelButton.emit(event);
    } else {
      this.dynamicDialogRef.close();
    }
  }

  public actionClickedAndConfirmed(event: MouseEvent): void {
    if (!this.preventActionButtonLoading) {
      this.isActionButtonLoading = true;
      this.statusEventService.setHttpStatus(true);
    }
    this.actionButton.emit(event);
  }
}
