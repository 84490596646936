<stiilt-dialog>
  <ng-template stiiltTemplate="header">
    <div class="flex items-center justify-between top-0 p-5 flex-shrink-0">
      <div class="flex gap-5 items-center">
        <h2 class="text-2xl">{{ 'parking_tickets.patch_dialog.title' | transloco }}</h2>
        <stiilt-color-badge-status
          *ngIf="parkingTicket.requiresInput && parkingTicket.manualInputReason"
          [color]="ColorBadgeStatusColor.RED">
          {{ 'parking_tickets.' + parkingTicket.manualInputReason | transloco }}
        </stiilt-color-badge-status>
      </div>
      <div class="p-1">
        <button
          tabindex="-1"
          (click)="dynamicDialogRef.close()"
          class="p-button p-button-rounded p-button-danger p-button-text grow justify-center"
          type="button">
          <i [pTooltip]="'app.close' | transloco" class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  </ng-template>
  <div class="flex-r gap-3">
    <div class="w-3/5">
      <form [formGroup]="parkingTicketConfirmationForm">
        <div class="flex flex-wrap p-fluid">
          <div class="field w-full md:w-1/2">
            <span class="mt-2 p-float-label">
              <input pInputText id="brand" name="brand" formControlName="brand" />
              <label for="brand">{{ 'parking_tickets.patch_dialog.brand' | transloco }}</label>
            </span>
          </div>
          <div class="field w-full md:w-1/2">
            <span class="mt-2 p-float-label">
              <input pInputText id="licencePlate" name="licencePlate" formControlName="licencePlate" />
              <label for="licencePlate">{{ 'parking_tickets.patch_dialog.licencePlate' | transloco }}</label>
            </span>
          </div>
          <div class="field w-full md:w-1/2">
            <div class="p-inputgroup">
              <span class="p-float-label">
                <input
                  pInputText
                  id="paymentNotice"
                  name="paymentNotice"
                  formControlName="paymentNotice"
                  [minlength]="26"
                  [maxlength]="26" />
                <label for="paymentNotice">{{ 'parking_tickets.patch_dialog.paymentNotice' | transloco }}</label>
              </span>
              <span
                class="p-inputgroup-addon"
                [pTooltip]="paymentNotice"
                [tooltipOptions]="tooltipOptions"
                [fitContent]="true"
                tooltipStyleClass="image-override">
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </div>
          </div>
          <div class="field w-full md:w-1/2">
            <div class="p-inputgroup">
              <span class="p-float-label">
                <input
                  pInputText
                  id="paymentKey"
                  name="paymentKey"
                  formControlName="paymentKey"
                  [minlength]="2"
                  [maxlength]="2" />
                <label for="paymentKey">{{ 'parking_tickets.patch_dialog.paymentKey' | transloco }}</label>
              </span>
              <span
                class="p-inputgroup-addon"
                [pTooltip]="paymentKey"
                [tooltipOptions]="tooltipOptions"
                [fitContent]="true"
                tooltipStyleClass="image-override">
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </div>
          </div>
          <div class="field w-full md:w-1/2">
            <span class="mt-2 p-float-label">
              <input pInputText id="amount" name="amount" formControlName="amount" />
              <label for="amount">{{ 'parking_tickets.patch_dialog.amount' | transloco }}</label>
            </span>
          </div>
          <div class="field w-full md:w-1/2">
            <stiilt-calendar
              id="date"
              formControlName="date"
              [showTime]="true"
              [label]="'parking_tickets.patch_dialog.date' | transloco"></stiilt-calendar>
          </div>
        </div>
      </form>
    </div>
    <div class="w-2/5">
      <stiilt-embedded-pdf-viewer *ngIf="parkingTicket?.path" [pdfUrl]="getPdfUrl()"></stiilt-embedded-pdf-viewer>
    </div>
  </div>
  <ng-template stiiltTemplate="footer">
    <div class="flex gap-2.5 card flex-wrap justify-content-center">
      <p-button
        (click)="handleOnDelete($event)"
        [label]="'parking_ticket.delete' | transloco"
        severity="danger"
        size="small"
        data-cy="cancelButton"
        type="button"></p-button>
      <p-button
        (click)="handleOnSave()"
        [label]="'parking_ticket.confirmed' | transloco"
        [disabled]="parkingTicketConfirmationForm.invalid"
        size="small"
        data-cy="submitButton"
        type="button"></p-button>
    </div>
  </ng-template>
</stiilt-dialog>
<ng-template #paymentNotice>
  <img src="assets/hints/payment_notice.jpg" class="h-full" alt="payment notice hint" />
</ng-template>
<ng-template #paymentKey>
  <img src="assets/hints/payment_key.jpg" class="h-full" alt="payment key hint" />
</ng-template>
