export enum ProcessingBatchPaymentStatus {
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CAPTURE = 'requires_capture',
  CANCELED = 'canceled',
  REFUNDED = 'refunded',
  PAYMENT_ERROR = 'payment_error',
  PENDING_PAYMENT = 'pending_payment',
  EXPIRED = 'expired',
  BLOCKED = 'blocked',
}
