import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { loadStripe, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import { from, map, Observable } from 'rxjs';
import { PaymentIntentResult } from '@stripe/stripe-js/dist/stripe-js/stripe';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripe: Stripe | null | undefined;
  private stripeElements: StripeElements | null | undefined;

  constructor(private readonly env: EnvService) {}

  public init(): Observable<Stripe | null> {
    return from(loadStripe(this.env.stripePublishableKey!)).pipe(
      map((stripe) => {
        this.stripe = stripe;
        return stripe;
      }),
    );
  }

  public getInstance(): Stripe {
    return this.stripe!;
  }

  public getElements(): StripeElements {
    return this.stripeElements!;
  }

  public confirmCardPaymentWithExistingPaymentMethod(
    clientSecret: string,
    paymentMethod: string,
  ): Observable<PaymentIntentResult> {
    return from(
      this.getInstance().confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
      }),
    );
  }

  public confirmCardPaymentNewCard(
    clientSecret: string,
    number: StripeCardNumberElement,
    saveCardForFutureUsage: boolean,
  ): Observable<PaymentIntentResult> {
    return from(
      this.getInstance().confirmCardPayment(clientSecret, {
        payment_method: {
          card: number,
        },
        setup_future_usage: saveCardForFutureUsage ? 'off_session' : undefined,
        save_payment_method: saveCardForFutureUsage,
      }),
    );
  }
}
