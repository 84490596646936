import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'stiilt-color-badge-status',
  standalone: true,
  templateUrl: './color-badge-status.component.html',
  styleUrls: ['./color-badge-status.component.scss'],
  imports: [NgClass, TooltipModule],
})
export class ColorBadgeStatusComponent {
  @Input({ required: true }) public color!: ColorBadgeStatusColor;
  @Input() public tooltip = '';
}

export enum ColorBadgeStatusColor {
  RED = 'red',
  GREEN = 'green',
  ORANGE = 'orange',
  GREY = 'grey',
  BLUE = 'blue',
  YELLOW = 'yellow',
  INDIGO = 'indigo',
}
