import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import { TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';

@Pipe({
  name: 'fallbackTranslate',
  standalone: true,
})
export class NotSpecifiedPipe implements PipeTransform {
  constructor(public translateService: TranslocoService) {}

  public transform(
    value: unknown,
    translationKeyIfNotNil: string,
    translationKeyIfNil = 'app.not_recognized',
    interpolateParams?: unknown,
  ): Observable<unknown> {
    return isNil(value)
      ? this.translateService.selectTranslate(translationKeyIfNil, interpolateParams ?? { value })
      : this.translateService.selectTranslate(translationKeyIfNotNil, interpolateParams ?? { value });
  }
}
