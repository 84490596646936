<p-sidebar [(visible)]="showSidebar" position="right" appendTo="body" styleClass="w-full xl:w-[50rem]"
  (onShow)="handleOnShow()" (onHide)="handleOnHide()">
  <ng-template pTemplate="header">
    <div class="flex flex-col">
      <h3 class="text-2xl font-semibold leading-6 text-royal-blue">{{ clickedParkingTicket?.paymentNotice |
        fallbackTranslate: 'parking_tickets.pt_number_with_value' : 'parking_tickets.pt_number_not_recognized' | async
        }}</h3>
      <h4 class="font-semibold text-lg"
        [innerHTML]="clickedParkingTicket?.licencePlate | fallbackTranslate: 'parking_tickets.licencePlate_title' : 'parking_tickets.licence_plate_not_recognized' | async">
      </h4>
    </div>
  </ng-template>
  <ul class="pb-[1.25rem]" *ngIf="visible">
    <li *ngFor="let field of fieldsToDisplay">
      <div class="flex-r gap-3">
        <span class="font-semibold">{{ 'parking_tickets.' + field | transloco }}</span>
        @switch (field) {
        @case ('amount') {
        <span>{{ clickedParkingTicket?.amount ? ('app.units.euro' | transloco: { amount: clickedParkingTicket?.amount })
          : 'parking_tickets.unknown_price' | transloco }}</span>
        }
        @case ('offenceDate') {
        <span>{{ clickedParkingTicket?.offenceDate! | translocoDate }}</span>
        }
        @case ('address') {
        <span>{{ processingBatchUtilsService.constructAddress(clickedParkingTicket?.address!) }}</span>
        }
        @case ('parkingTicketSentAt') {
        <span>{{ clickedParkingTicket?.parkingTicketSentAt! | translocoDate }}</span>
        }
        @default {
        <span>{{ getValue(field) }}</span>
        }
        }
      </div>
    </li>
  </ul>
  <div class="flex flex-col gap-4">
    <stiilt-embedded-pdf-viewer *ngIf="paymentProof" [pdfUrl]="paymentProof"></stiilt-embedded-pdf-viewer>

    <stiilt-embedded-pdf-viewer *ngIf="filePathToDisplay" [pdfUrl]="filePathToDisplay"></stiilt-embedded-pdf-viewer>
  </div>
</p-sidebar>
