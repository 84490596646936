import { ChangeDetectionStrategy, Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'stiilt-embedded-pdf-viewer',
  templateUrl: './embedded-pdf-viewer.component.html',
  styleUrls: ['./embedded-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class EmbeddedPdfViewerComponent implements OnInit {
  @Input({ required: true }) public pdfUrl!: string;
  public _pdfUrl!: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // Using a local variable to avoid component flickering.
    this._pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.sanitizer.sanitize(SecurityContext.URL, this.pdfUrl)!,
    );
  }
}
