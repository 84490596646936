import { UntilDestroy } from '@ngneat/until-destroy';
import { PageChangeEvent } from '@shared/models/paginator.model';
import { TableLazyLoadEvent } from 'primeng/table';
import { Constants, ORDER_BY_DIRECTION } from '../constants/constants';
import { PaginationService } from '../services/pagination.service';

@UntilDestroy()
export abstract class AbstractPaginatedComponent<T> {
  public entities: T[] = [];
  public tableColumns: {
    name: string;
    field: string;
  }[] = [];
  public paging = true;
  public page: number = Constants.DEFAULT_FIRST_PAGE_NUMBER;
  public size: number = Constants.DEFAULT_PAGE_SIZE;
  public totalCount: number | undefined;
  public isLoading: boolean = false;
  public search?: string;
  public currentOrderByField!: keyof T;
  public currentOrderByDirection!: ORDER_BY_DIRECTION;
  public unfilteredTotalCount: number | undefined;
  public onlyOnePage: boolean = true;

  protected paginationService!: PaginationService;

  onLazyLoad(event: TableLazyLoadEvent): void {
    const pageChangeEvent = this.paginationService.getPageChangeEvent(event);
    this.page = pageChangeEvent.index + 1;
    this.size = pageChangeEvent.size;
    if (event.sortField && event.sortOrder) {
      this.currentOrderByField = event.sortField as keyof T;
      this.currentOrderByDirection = event.sortOrder === 1 ? ORDER_BY_DIRECTION.ASC : ORDER_BY_DIRECTION.DESC;
    }
    this.onPageOrSortChange(pageChangeEvent);
  }

  abstract onPageOrSortChange(pageChangeEvent?: PageChangeEvent): void;
}
