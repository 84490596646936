<div class="flex-col-center h-full w-full">
  <stiilt-title [title]="'static_fleet.title' | transloco"></stiilt-title>
  <p class="pt-16 text-center">
    {{ 'static_fleet.description' | transloco }}
  </p>
  <!--  <span>Date du dernier référenciel: XX/XX/XXX</span>-->
  <div class="pt-8 pb-8 w-full max-w-6xl">
    <stiilt-upload [hintText]="'upload.only_csv_or_tsv'| transloco" (filesAdded)="handleFilesAdded($event)"
                   [uploadType]="UploadType.CSV"></stiilt-upload>
  </div>

  <stiilt-alert *ngIf="parsingError" [type]="AlertType.DANGER"
                [alertText]="'static_fleet.parsing_error' | transloco" />

  <stiilt-alert *ngIf="isMissingMandatoryField" [type]="AlertType.DANGER"
                [alertText]="'static_fleet.all_mandatory_fields_not_present' | transloco" />

  <stiilt-alert *ngIf="canContinue" [type]="AlertType.SUCCESS"
                [alertTitleText]="'static_fleet.can_continue_title' | transloco"
                [alertText]="'static_fleet.can_continue' | transloco" />

  <p-button [label]="'static_fleet.download_csv_sample' | transloco" [link]="true" (click)="downloadFile()"></p-button>
  <div class="flex flex-row items-center gap-2">
    <span class="pt-2">{{ 'static_fleet.sample_hint' | transloco }}</span>
  </div>

  <div
    class="flex w-full justify-center md:justify-end max-w-6xl pt-5">
    <div *ngIf="!canContinue && !updateFleetOnly" class="flex items-center mr-3">
      <p-checkbox inputId="skip" [binary]="true" [(ngModel)]="skipUpdate"></p-checkbox>
      <label class="ml-3 text-sm leading-6 text-gray-900"
             for="skip">{{ 'static_fleet.skip_database_update' | transloco }}</label>
    </div>
    <p-button [disabled]="!canContinue && !skipUpdate" [loading]="(statusEventService.getHttpStatus() | async)!"
              (onClick)="patchStaticFleet()">{{ 'static_fleet.upload' | transloco }}
    </p-button>
  </div>
</div>
