import { Injectable, Type } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';
import { DialogResult } from '../models/dialog-result.model';

export enum DialogWidth {
  SMALL = '30rem',
  MEDIUM = '60rem',
  LARGE = '90rem',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class StiiltDialogService {
  private cancel = new Subject<DialogResult<any>>();
  private success = new Subject<DialogResult<any>>();

  constructor(private dialogService: DialogService) {}

  public onCancel(): Observable<DialogResult<unknown>> {
    return this.cancel.asObservable();
  }

  public onSuccess<T>(): Observable<DialogResult<T>> {
    return this.success.asObservable();
  }

  public show<T>(
    component: Type<unknown>,
    data?: DynamicDialogConfig<T>,
    dialogSize: DialogWidth = DialogWidth.MEDIUM,
  ): DynamicDialogRef {
    this.cancel = new Subject<DialogResult<unknown>>();
    this.success = new Subject<DialogResult<unknown>>();

    const dynamicDialogRef = this.dialogService.open(component, {
      ...data,
      showHeader: false,
      width: dialogSize,
      closable: true,
      styleClass: 'mx-3',
      appendTo: 'body',
    });

    dynamicDialogRef.onClose.pipe(untilDestroyed(this)).subscribe((result) => {
      if (result) {
        this.success.next({ hasSaving: true, data: result });
      } else {
        this.cancel.next({ hasSaving: false });
      }
    });

    this.handleBackButton(dynamicDialogRef);

    return dynamicDialogRef;
  }

  private handleBackButton(dialogRef: DynamicDialogRef): void {
    const popstateListener = (event: any) => {
      if (event.state.stepIndex === undefined) {
        dialogRef.close();
      }
    };

    history.pushState({ dialogOpen: true }, document.title);

    window.addEventListener('popstate', popstateListener);
  }
}
