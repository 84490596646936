<div class="flex-row-center">
  <p-selectButton [allowEmpty]="false" [options]="stateOptions" [(ngModel)]="periodicity" optionLabel="label"
                  optionValue="value"></p-selectButton>
</div>
<div class="isolate mx-auto mt-5 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
  @for (plan of existingPlans; track plan.id) {
    <div class="relative rounded-3xl p-8 xl:p-10 flex flex-col justify-between"
         [ngClass]="{'bg-active-gray-blue': isCurrentPlan(plan.type), 'ring-1  hover:cursor-pointer': !isCurrentPlan(plan.type)}"
         (click)="handlePlanSelection(plan)">
      <div>
        @if (selectedPaymentPlan?.type === plan.type && selectedPeriodicity === periodicity) {
          <div
            class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-secondary-dark-blue rounded-full -top-2 -end-2 dark:border-gray-900">
            <i class="fa-solid fa-check"></i>
          </div>
        }
        <span
          class="text-md text-[#a4acc5] font-sans">{{ 'account_plan.personal_information.plans.' + plan.type | transloco }}</span>
        <h3 class="text-base font-semibold leading-7 text-dark-blue flex items-baseline gap-x-1">
        <span
          class="text-4xl font-bold tracking-tight text-secondary-dark-blue font-sans"
          [innerHTML]="'account_plan.personal_information.euro_price' | transloco: { value: getMonthlyPrice(plan) }"></span>
          <span
            class="text-sm font-semibold leading-6">{{ 'account_plan.personal_information.per_month_label' | transloco }}</span>
        </h3>
        <p
          class="mt-2 text-xs leading-6">{{ 'account_plan.personal_information.plans_short_description.' + plan.type | transloco }}</p>
        <ul role="list" class="mt-2 space-y-1 text-sm leading-6 xl:mt-5">
          @for (feature of plan.features; track feature.en) {
            <li class="flex-r items-center gap-x-3 font-sans text-gray-blue">
              <i class="fa-solid fa-circle-check"></i>
              {{ feature[currentLang] }}
            </li>
          }
        </ul>
      </div>
      @if (isCurrentPlan(plan.type)) {
        <div class="flex-r justify-center">
          <p class="font-sans mt-5 text-lg leading-6">{{ 'account_plan.personal_information.current' | transloco }}</p>
        </div>
      }
    </div>
  }
</div>

