<section class="flex flex-row w-full">
<!--  <header class="mr-4 flex-c flex-initial justify-around" *ngIf="showHeader">-->
<!--    <ng-container *ngFor="let step of steps; let i = index">-->
<!--      <div-->
<!--        (click)="selectedIndex = i"-->
<!--        class="cursor-pointer flex items-center px-2 py-4 step"-->
<!--        [ngClass]="{-->
<!--          'selected-step': selectedIndex === i,-->
<!--          'completed-step': step.completed,-->
<!--        }">-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </header>-->

  <div class="flex-1 stepper-content px-4">
    <ng-container [ngTemplateOutlet]="selected!.content"></ng-container>
  </div>
</section>
